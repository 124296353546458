import React from 'react';
import { graphql } from 'gatsby';
import ProjectCard from '../templates/project-card';
import Masonry from 'react-masonry-css';
import { Layout } from '../components/layout';
import map from 'lodash/map';
import './index.scss';

const IndexPage = ({ data }) => {
    const posts = data.allMarkdownRemark.nodes;
    const breakpointColumnsObj = {
        default: 3,
        767: 2,
        575: 1,
    };

    return (
        <Layout className="index-page">
            <div className="project-grid-container">
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="project-grid"
                    columnClassName="project-grid--column"
                >
                    {map(posts, (post) => {
                        const {
                            title,
                            thumbnail,
                            date,
                            slug,
                            short_description
                        } = post.frontmatter;

                        return (
                            <ProjectCard
                                key={slug}
                                slug={slug}
                                thumbnail={{
                                    gatsbyImageData:
                                        thumbnail?.img.childImageSharp
                                            ?.gatsbyImageData,
                                    alt_text: thumbnail?.alt_text,
                                }}
                                title={title}
                                date={date}
                                subtitle={short_description}
                            />
                        );
                    })}
                </Masonry>
            </div>
        </Layout>
    );
};

export default IndexPage;

export const pageQuery = graphql`
    {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            sort: {
                fields: [frontmatter___sort_order, frontmatter___date]
                order: [ASC, DESC]
            }
        ) {
            nodes {
                frontmatter {
                    title
                    category
                    slug
                    short_description
                    thumbnail {
                        img {
                            childImageSharp {
                                gatsbyImageData(placeholder: DOMINANT_COLOR)
                            }
                        }
                        alt_text
                    }
                }
            }
        }
    }
`;
