import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Heading, Text } from '../components/typography';
import './project-card.scss';

const ProjectCard = ({ slug, thumbnail, title, subtitle }) => {
    return (
        <article
            className="project-card"
            itemScope
            itemType="http://schema.org/CreativeWork"
        >
            <Link
                to={`/projects/${slug}`}
                itemProp="url"
                className="project-card--container"
            >
                <GatsbyImage
                    image={thumbnail.gatsbyImageData}
                    className="project-card--thumbnail"
                    alt={thumbnail.alt_text}
                />
                <div className="project-card--details">
                    <Heading
                        level={2}
                        size="small"
                        className="project-card--title"
                        itemProp="headline"
                    >
                        {title}
                    </Heading>
                    <Text
                        size="subtitle"
                        className="project-card--subtitle"
                        itemProp="keywords"
                    >
                        {subtitle}
                    </Text>
                </div>
            </Link>
        </article>
    );
};

export default ProjectCard;
